import React, { useState, useEffect, useMemo } from "react"
import Layout from "../../components/Layout"
import Img from "gatsby-image"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"
import Popup from "../../componentForPages/Popup/Popup"

import NavBar from "../../components/NavBar/NavBar"
import { isSSR } from "../../components/Script/script"
import {
  Title,
  SubTitle,
  Columns,
  Column,
  Button,
} from "../../StyleComponents/styles"
import { FlexDiv } from "../../StyleComponents/pagesStyle"

const GoogleAds = React.lazy(() => import("../../components/ADS/ADS"))

function Namegenerator(props) {
  const { node } = props.pageContext
  let [result1, setresult1] = useState([])
  let [poupInfo, setpoupInfo] = useState({})
  let [hide, setHide] = useState(true)
  let [closePoup, setclosePoup] = useState(true)

  useEffect(() => {
    randomizer(node.generate)
  }, [])

  function generate() {
    randomizer(node.generate)
  }

  function randomizer(list) {
    let NamesData = []
    for (let i = 0; i < 10; i++) {
      let random = Math.floor(Math.random() * list.length)
      NamesData.push({
        name: list[random].NAME,
        info: list[random].INFO,
      })
    }
    setresult1(NamesData)
    setHide(false)
  }
  function myFunction(name, info) {
    setpoupInfo({ name: name, info: info })
    setclosePoup(false)
  }
  const closeFu = () => {
    setclosePoup(true)
  }
  return (
    <Layout location={props.location}>
      <SEO
        title={`Random ${node.name} Generator`}
        description={`Simply press on the button "generate" to generate a random ${node.name}, wee help you to find the perfect ${node.name}.`}
        keywords={[
          `${node.name} generator, ${node.name} , random ${node.name} generator,random ${node.name} name,${node.name} name generator, generate ${node.name}, generate random ${node.name}`,
        ]}
      />
      <NavBar
        listPages={useMemo(() => {
          return [
            { name: "Generator", link: "/generator/" },
            { name: `${node.name} Generator`, link: `/${node.path}/` },
          ]
        }, [node.name, node.path])}
      />
      <section className="container">
        <Title>{node.name} generator</Title>
        <SubTitle>Random {node.name} Generator</SubTitle>
        <br />
        <Columns>
          <Column>
            <FlexDiv textAlign="center" minHeight="400px">
              <Button
                className="mb-3"
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                fontSize="1.5rem"
                value="Generate"
                onClick={generate}
              >
                Generate
              </Button>
              <br />
              {!closePoup ? (
                <Popup
                  title={poupInfo.name}
                  emoji={poupInfo.info}
                  close={closeFu}
                />
              ) : (
                ""
              )}
              {hide ? (
                ""
              ) : (
                <div>
                  {result1.map((e, index) => {
                    return (
                      <p className="h5" key={index}>
                        {e.name}{" "}
                        <Button
                          className="ml-3"
                          border="0"
                          style={{ padding: "0" }}
                          backgroundColor="transparent"
                          hoverBackgroundColor="transparent"
                          color="#007bff"
                          hoverColor="#0056b3"
                          role="presentation"
                          onClick={() => myFunction(e.name, e.info)}
                        >
                          <span className="h5">&#8505;</span>
                        </Button>
                      </p>
                    )
                  })}
                </div>
              )}{" "}
            </FlexDiv>
          </Column>
          <Column>
            {/* google ads UnitPediaRight */}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
              </React.Suspense>
            )}
          </Column>
        </Columns>
        <br />
        <FlexDiv maxWidth="250px" margin="auto">
          <Img
            fluid={node.img.childImageSharp.fluid}
            alt={`random ${node.name} generator`}
          />
        </FlexDiv>
        <br />
        <div>
          <h3>Random {node.name} Generator</h3>
          <p>
            Simply press on the button "generate" to generate a random{" "}
            {node.name}, wee help you to find the perfect {node.name}
          </p>
        </div>
        <br />
        <SeeAlso seeAlsoList={node.SeeAlso} />
        <br />
      </section>
    </Layout>
  )
}

export default Namegenerator
