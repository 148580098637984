import React, { useEffect } from "react"
import { Button, IconButton } from "../../StyleComponents/styles"
import {
  PopupModal,
  ModalDialog,
  ModalHeader,
  ModalTitle,
  ModalContent,
  ModalBody,
  ModalFooter,
} from "../../StyleComponents/pagesStyle"

function Popup({ title, emoji, close }) {
  useEffect(() => {
    ;(() => {
      let elem = document.createElement("textarea")
      document.body.appendChild(elem)
      elem.value = emoji
      elem.select()
      let copied = document.execCommand("copy")
      document.body.removeChild(elem)
      if (copied) {
        console.log("copied")
      }
    })()
  }, [emoji])

  return emoji ? (
    <PopupModal
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <ModalDialog role="document">
        <ModalContent>
          <ModalHeader>
            <ModalTitle id="exampleModalCenterTitle">
              {title}
            </ModalTitle>

            <IconButton
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={close}
            >
              <span aria-hidden="true">&times;</span>
            </IconButton>
          </ModalHeader>

          <ModalBody>
            <p className="h5">{emoji}</p>
            {/* <hr />
              <p>This link and have tooltips on hover.</p> */}
          </ModalBody>

          <ModalFooter>
            <Button
              type="button"
              className="mb-2"
              borderColor="#ea4336"
              color="#ea4336"
              hoverBorderColor="#ea4336"
              hoverBackgroundColor="#ea4336"
              hoverColor="white"
              data-dismiss="modal"
              onClick={close}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalDialog>
    </PopupModal>
  ) : null
}

export default Popup
